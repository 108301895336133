<template>
  <v-dialog v-model="dialog" persistent max-width="900px" @keydown.esc="cancel">
    <v-card>
      <v-card-title>
        <span class="headline">Create User</span>
      </v-card-title>
      <v-card-text>
        <Form ref="form" v-model="valid" lazy-validation autocomplete="off">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field autocomplete="off" v-model="firstName" label="First Name*" :rules="nameRules" required dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field autocomplete="off" v-model="lastName" label="Last Name*" :rules="nameRules" required dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field autocomplete="new-email" v-model="email" label="Email*" :rules="emailRules" required dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field autocomplete="new-password" v-model="password" label="Password*" :rules="passwordRules" type="password" required dense></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </Form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-checkbox v-if="!user" v-model="createAnother" label="Create Another" dense />
        <v-btn class="ml-3 px-5" @click="cancel">Cancel</v-btn>
        <v-btn class="ml-3 px-5" color="primary" @click="create">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { rules } from '@/helpers/validation';

export default {
  name: 'CreateUser',
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      createAnother: false,
      valid: false,
      nameRules: [rules.required, rules.moreThanTwo],
      emailRules: [rules.required, rules.email],
    };
  },
  created() {
    if (this.user) {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
    }
  },
  computed: {
    ...mapState(['formDirty']),
    passwordRules() {
      if (this.user) {
        if (this.password) {
          return [rules.required, rules.password];
        } else {
          return [];
        }
      } else {
        return [rules.required, rules.password];
      }
    },
  },
  methods: {
    ...mapActions(['createUser', 'updateUser']),
    async create() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.user) {
        const res = await this.updateUser({ id: this.user.id, firstName: this.firstName, lastName: this.lastName, email: this.email, password: this.password });
        if (!res) {
          return;
        }
      } else {
        const res = await this.createUser({ firstName: this.firstName, lastName: this.lastName, email: this.email, password: this.password });
        if (!res) {
          return;
        }
      }

      this.firstName = '';
      this.lastName = '';
      this.email = '';

      if (!this.createAnother) {
        this.close();
      } else {
        this.$refs.form.reset();
      }
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
    cancel() {
      if (this.formDirty && confirm('Are you sure you want to cancel?\nChanges you made will not be saved.') === false) {
        return;
      }
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
