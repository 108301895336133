<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Users</span>
        <v-spacer></v-spacer>
        <div class="max-w-4xl pr-3">
          <v-text-field v-model="searchText" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </div>
        <v-btn class="mt-3" color="primary" @click="openCreateModal">
          <v-icon>mdi-plus</v-icon>
          <span class="ml-2">Create</span>
        </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="users" :search="searchText">
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateUser from '@/components/admin/CreateUser';

export default {
  name: 'Users',
  data: () => ({
    headers: [
      { text: 'First Name', value: 'firstName' },
      { text: 'Last Name', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px', align: 'end' },
    ],
    searchText: '',
  }),
  computed: {
    ...mapState(['users']),
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions(['fetchUsers', 'deleteUser']),
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: () => {
          this.deleteUser(item.id);
          modal.close();
        },
      });
    },
    openCreateModal() {
      this.$showModal(CreateUser);
    },
    editItem(item) {
      this.$showModal(CreateUser, { user: item });
    },
  },
};
</script>
